<template>
  <v-container id="carousel" fluid  fill-height justify-center>
    <v-img contain src="@/assets/main/banner_heading.jpg" max-height="20vh" max-width="55vh">
      <v-row class="fill-height ma-0" align="center" justify="center">
        <h3 class="display-3" style="color: white; font-weight: bold;">Rückblick</h3>
      </v-row>
    </v-img>

    <v-carousel
      cycle
      hide-delimiter-background
      show-arrows-on-hover
      height="80vh"
      class="pt-5"
    >
      <v-carousel-item eager v-for="(item, i) in items" :key="i" :src="item.src">
          <v-container fill-height>
            <v-row class="white--text mx-auto">
              <v-col class="white--text text-left">
                <span>
                  <p class="carousel-heading" v-text="item.heading"></p>
                </span>
                <span>
                  <p class="carousel-text" v-text="item.text"></p>
                </span>
              </v-col>
            </v-row>
          </v-container>
      </v-carousel-item>
    </v-carousel>
  </v-container>
</template>
<script>
export default {
  data () {
    return {
      items: [
                {
          src: require('@/assets/carousel/3.jpg'),
          heading: '10. Januar 2012',
          text: "Nach etwa 10 Jahren als Paar wurden wir am 30.07 standesamtlich und am 2. April kirchlich getraut"
        },
        {
          src: require('@/assets/carousel/1.jpg'),
          heading: '30. Juli 2021',
          text: "Im Rathaus Lichtenwald gaben wird uns in Anwesenheit der Familie das standesamtliche Ja-Wort"
        },
        {
          src: require('@/assets/carousel/2.jpg'),
          heading: '2. April 2022',
          text: "In Anwesenheit von Familie und Freunden gaben wir uns das zweite Ja-Wort"
        },
        {
          src: require('@/assets/carousel/amy.jpg'),
          heading: '23. Mai 2022',
          text: "Na, wer ist denn da? Amaya sagt hallo :)"
        }
      ]
    }
  },
}
</script>
<style scoped>
.carousel-heading {
  font-size: 5vh;
}
.carousel-text {
  font-size: 3vh;
}
</style>
