<template>
  <v-container fluid fill-height>
    <v-row align="center">
      <v-col
        cols="false"
        xl="7"
        lg="7"
        class="ma-0 pa-0 d-none d-xs-flex d-sm-flex d-md-flex"
      >
        <v-img
          class="white--text align-end background"
          src="@/assets/upload/upload.jpg"
        >
          <v-row align="center" justify="center" class="white--text mx-auto">
            <span>
              <p class="carousel-heading align-center">
                | <router-link v-bind:to="'impressum'">Impressum</router-link> |
                <router-link v-bind:to="'datenschutz'">Datenschutz</router-link> |
                <router-link v-bind:to="'nutzungsbedingungen'">Nutzungsbedingungen</router-link> |
              </p>
            </span>
          </v-row>
        </v-img>
      </v-col>
      <v-col xl="5" lg="5" md="5" sm="12" xs="12">
        <v-img
          contain
          class="center mb-8"
          src="@/assets/login/logo.png"
          width="25vh"
        >
        </v-img>
        <v-row justify="center"> Bitte anmelden, um fortzufahren. </v-row>
        <v-row justify="center">
          <v-col cols="10" sm="10" md="10">
            <v-text-field
              type="email"
              color="lightgray"
              v-model="username"
              clearable
              label="Benutzername"
              prepend-icon="mdi-email"
              placeholder="E-Mail oder Benutzername"
              v-on:keyup.enter="onEnter"
              :rules="[rules.email]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="10" sm="10" md="10">
            <v-text-field
              type="password"
              color="lightgray"
              v-model="password"
              clearable
              label="Passwort"
              prepend-icon="mdi-lock"
              v-on:keyup.enter="onEnter"
              @click:append="search()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn @click.stop="login()" elevation="2">
            Anmelden
            <v-icon>mdi-login</v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => (
    {
      username: '',
      password: '',
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Ungültige E-Mail Adresse.'
        },
      },
    }
  ),
  mounted () {
    this.disableNavbar();
  },
  methods: {
    disableNavbar () {
      this.$emit('setnavbar', false)
    },

  }
}
</script>
<style scoped>
a {
  color: white !important;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  color: gray !important;
  text-decoration: none;
  cursor: pointer;
}
.background {
  height: 100vh;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>