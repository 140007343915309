// /src/plugins/axios.js
import axios from 'axios';

// doing something with the request
axios.interceptors.request.use(
  (request) => {
    // do something with request meta data, configuration, etc
    // dont forget to return request object,
    // otherwise your app will get no answer
    return request;
  }
);

// doing something with the response
axios.interceptors.response.use(
  (response) => {
     // all 2xx/3xx responses will end here
     
     return response;
  },
  (error) => {
     // all 4xx/5xx responses will end here
     return Promise.reject(error);
  }
);

export default axios;