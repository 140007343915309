<template>
  <v-container fluid class="pa-0">
    <top/>
    <review/>
    <impressions/>
    <gallery/>
  </v-container>
</template>

<script> 
import Top from './main/Top.vue'
import Review from './main/Review.vue'
import Impressions from './main/Impressions.vue'
import Gallery from './Gallery.vue'
export default {
  name: 'Main',
  components : {
    Top,
    Review,
    Impressions,
    Gallery
  }
}
</script>
