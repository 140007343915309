<template>
  <v-container fluid ma-0 pa-0 fill-height>
    <v-row>
      <v-col cols="10" sm="10" md="10">
        <v-text-field
          v-model="searchQuery"
          label="Suche"
          placeholder="Begriff eingeben..."
          filled
          rounded
          dense
          v-on:keyup.enter="onEnter"
          append-icon="mdi-card-search"
          @click:append="search()"
        ></v-text-field>
      </v-col>
      <v-col cols="2" sm="2" md="2">
        <div class="my-2">
          <v-btn color="grey" fab x-small @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: 'Search',

  data: () => ({
    searchQuery: ''
  }),
  methods: {
    close () {
      console.log("closing search")
      this.$emit('closesearch', false)
    },
    onEnter: function () {
      this.search();
    },
    search () {
      this.$router.push({ name: 'search', query: { query: this.searchQuery } }).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          console.err(err);
        }
      });
      this.close()
    }
  }
}
</script>