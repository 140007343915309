<template>
  <v-container class="mt-3">
    <v-card>
      <v-img
        class="white--text align-end"
        height="200px"
        src="@/assets/search/result.jpg"
      >
        <v-card-title>Suchergebnisse</v-card-title>
      </v-img>
      <v-row class="text-center">
        <v-col cols="6" offset="3">
          <h1></h1>
        </v-col>
        <v-col cols="6" offset="3">
          <h3>Deine Suchergebnisse für "{{ query }}"</h3>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    query: ''
  }),
  mounted () {
    this.updateQuery();
  },
  methods: {
    updateQuery () {
      this.query = this.$route.query.query
    }
  },
  watch: {
    $route () {
      this.updateQuery();
    }
  },
}
</script>