<template>
  <v-container id="gallery" fluid  fill-height justify-center>
    <v-img contain src="@/assets/main/banner_heading.jpg" max-height="20vh" max-width="55vh">
      <v-row class="fill-height ma-0" align="center" justify="center">
        <h3 class="display-3" style="color: white; font-weight: bold;">Gallerie</h3>
      </v-row>
    </v-img>

    <v-row class="pt-5">
      <v-col xl="6" lg="6" md="6" sm="12" xs="12">
          <v-card-link 
            overline='30. Juli 2021' 
            title='Fotos der standesamtlichen Hochzeit'
            subtitle='Alle Bilder der standesamtlichen Hochzeit im Rathaus Lichtenwald findet ihr hier (das Passwort ist der Zweitname unserer Kleinen und das Jahr):'
            link='https://cloud.abouchleih.de/s/XwPPJaG7gAX2k5J'
            icon="scale-balance"
          >
          </v-card-link>a
          
      </v-col>
      <v-col xl="6" lg="6" md="6" sm="12" xs="12">
          <v-card-link 
            overline='2. April 2022' 
            title='Fotos der kirchlichen Hochzeit'
            subtitle='Alle Bilder der kirchlichen Hochzeit in der ev. Kirche und im Schloss Köngen findet ihr hier (das Passwort ist der Zweitname unserer Kleinen und das Jahr):'
            link='https://cloud.abouchleih.de/s/oZqnw8LwiLp7MY6'
            icon="church"
          >
          </v-card-link>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(image, index) in images"
        :key="index"
        class="d-flex child-flex"
          cols="6"
          sm="4"
          md="3"
      >
        <v-img
          :src="image.image"
          :lazy-src="image.lazyimage"
          aspect-ratio="1"
          class="grey lighten-2 cursor-pointer"
          @click="selectedItem = image"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-overlay v-if="selectedItem">
      <v-img max-width="80vw" :src="selectedItem ? selectedItem.image :''" contain @click="selectedItem = null"></v-img>
    </v-overlay>
  </v-container>
</template>

<script>
  import vCardLink from './helper/vCardLink.vue';
  export default {
  components: { vCardLink },

  name: 'Gallery',

  data: () => ({
    selectedItem: null,
    images: [
      {
        image: require("@/assets/gallery/vorspeise2.jpg"),
        lazyImage: require("@/assets/gallery/vorspeise2.jpg")
      },
      {
        image: require("@/assets/gallery/torte.jpg"),
        lazyImage: require("@/assets/gallery/torte.jpg")
      },
      {
        image: require("@/assets/gallery/just_married.jpg"),
        lazyImage: require("@/assets/gallery/just_married.jpg")
      },
      {
        image: require("@/assets/gallery/schloss.jpg"),
        lazyImage: require("@/assets/gallery/schloss.jpg")
      },
      {
        image: require("@/assets/gallery/vorspeise.jpg"),
        lazyImage: require("@/assets/gallery/vorspeise.jpg")
      },
      {
        image: require("@/assets/gallery/heft.jpg"),
        lazyImage: require("@/assets/gallery/heft.jpg")
      },
      {
        image: require("@/assets/gallery/lichtschwert.jpg"),
        lazyImage: require("@/assets/gallery/lichtschwert.jpg")
      },
      {
        image: require("@/assets/gallery/sweets.jpg"),
        lazyImage: require("@/assets/gallery/sweets.jpg")
      }
    ]
  }),
   created() {
    window.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        this.selectedItem = null
      }
    });
  },
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>