<template>
  <v-card outlined>
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4" v-text="overline"></div>
        <v-list-item-title class="text-h5 mb-1" v-text="title"></v-list-item-title>
        <v-list-item-subtitle v-text="subtitle"></v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar tile size="80" color="#9AFFD2">
          <v-icon>mdi-{{icon}}</v-icon>
      </v-list-item-avatar>
    </v-list-item>

    <v-card-actions>
      <v-btn outlined rounded text :href="link" target="_blank"> Hier klicken </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    overline: String,
    title: String,
    subtitle: String,
    icon: String,
    link: String
  },
};
</script>
