<template>
  <v-container id="review" fluid fill-height justify-center>
    <v-img
      contain
      src="@/assets/main/banner_heading.jpg"
      max-height="20vh"
      max-width="55vh"
    >
      <v-row class="fill-height ma-0" align="center" justify="center">
        <h3 class="display-3" style="color: white; font-weight: bold">
          Impressionen
        </h3>
      </v-row>
    </v-img>
    <v-row>
      <v-col
        xl="4"
        lg="4"
        md="12"
        sm="12"
        xs="12"
        class="intro-text align-end justify-end"
      >
        <v-card>
           <v-img
            height="350"
            src="@/assets/impressions/fotoshooting.jpg"></v-img>

          <v-card-title>Aufbruch zum Fotoshooting ...</v-card-title>

          <v-card-text>
               <div class="my-4 text-subtitle-1">• Wasserrückhaltebecken Lehenbach, Winterbach</div>
            <div>
              Mit <a href="https://www.fotografie-adrian.de/">Lucas</a>, Benni und Tanja ging es zu Beginn des Tages nach Winterbach. <br/>
              Vielen Dank für die tollen Bilder! <br/> 
              &nbsp;
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-title>Uhrzeit</v-card-title>

          <v-card-text>
              <v-icon>mdi-clock</v-icon> <v-chip>10:00 Uhr</v-chip>
          </v-card-text>
        </v-card>
      </v-col>
            <v-col
        xl="4"
        lg="4"
        md="12"
        sm="12"
        xs="12"
        class="intro-text align-end justify-end"
      >
        <v-card>
           <v-img
            height="350"
             src="@/assets/impressions/kirche.jpg"></v-img>

          <v-card-title>... weiter in der Kirche ...</v-card-title>

          <v-card-text>
               <div class="my-4 text-subtitle-1"> • Ev. Auferstehungskirche Hegenlohe, Lichtenwald</div>
            <div>
              Die kirchliche Trauung war sehr emotional. Unserer Pfarrerin Salzger führte uns durch einen sehr schönen Gottesdienst.<br/>
              Musikalisch begleitet wurde das Ganze von Jana und Herrn Salzger an der Orgel. <br/> 
              &nbsp;
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-title>Uhrzeit</v-card-title>

          <v-card-text>
              <v-icon>mdi-clock</v-icon> <v-chip>15:00 Uhr</v-chip>
          </v-card-text>
        </v-card>
      </v-col>
            <v-col
        xl="4"
        lg="4"
        md="12"
        sm="12"
        xs="12"
        class="intro-text align-end justify-end"
      >
        <v-card>
           <v-img
            height="350"
            src="@/assets/impressions/schloss.jpg"></v-img>

          <v-card-title>... und Abschluss im Schloss</v-card-title>

          <v-card-text>
               <div class="my-4 text-subtitle-1"> • Schloss Köngen</div>
            <div>
              Im Schloss angekommen erwartet uns ein wunderschön dekorierter Festsaal. <br/>
              Unserer Caterer Benz, unsere Gärtnerin Stefanie vom Blumen Maier haben unseren Raum in neuem Glanz erscheinen lassen. <br/>
              Alles war perfekt!
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <v-card-title>Uhrzeit</v-card-title>

          <v-card-text>
              <v-icon>mdi-clock</v-icon> <v-chip>17:00 Uhr</v-chip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {};
</script>
<style scoped>
@font-face {
  font-display: auto;
  font-family: "LovelyHome";
  src: local("LovelyHome"),
    url("/fonts/LovelyHome/LovelyHome.ttf") format("truetype");
}
.intro {
  background: #f8fefa;
  color: rgb(123, 153, 169);
}
.intro-heading {
  font-weight: normal;
  font-family: "Tahoma";
}
</style>