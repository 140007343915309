<template>
  <v-app id="inspire">
    <v-main>
      <Navbar
        v-if="shownavbar"
        @setdrawer="setdrawer"
        @opensearch="opensearch"
      />

      <v-overlay opacity="0.95" :value="search">
        <search @closesearch="closesearch" />
      </v-overlay>
      <router-view @triggeralert="triggeralert" @setnavbar="setnavbar"></router-view>
    </v-main>
    <v-alert
      v-model="alert.show"
      dismissible
      elevation="9"
      :type="alert.type"
    >{{alert.message}}</v-alert>
  </v-app>
</template>

<script>
import Navbar from "./components/header/Navbar.vue";

import Search from "./components/Search.vue";

import Main from "./components/Main.vue";
import Upload from "./components/Upload.vue";

export default {
  name: "App",
  Components: {
    Main,
    Upload,
  },
  components: {
    Navbar,
    Search,
  },
  data: () => ({
    alert: {
      show: false,
      message: "",
      type: "error"
    },
    shownavbar: true,
    drawer: false,
    search: false,
    group: null,
    items: [
      {
        icon: "home",
        text: "Home",
        link: "/",
      },
      {
        icon: "car",
        text: "foo",
        link: "/foo",
      },
    ],
  }),
  watch: {
    group() {
      this.drawer = false;
    },
    $route() {
      this.setnavbar(true);
      this.triggeralert({show: false})
    },
  },
  computed: {
    username() {
      return this.$route.params.username;
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    triggeralert({show, message, type="success"}) {
      this.alert.show = show;
      this.alert.message = message;
      this.alert.type = type;
      setTimeout(() => {
        this.alert.show = false;
      }, 6000);
    },
    opensearch() {
      this.search = true;
    },
    closesearch() {
      this.search = false;
    },
    setdrawer(drawer) {
      this.drawer = drawer;
    },
    setnavbar(navbar) {
      this.shownavbar = navbar;
    },
  },
};
</script>
<style scoped>
.v-alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
</style>