<template>
  <v-card class="overflow-hidden">
    <v-app-bar
      fixed
      color="white"
      elevation="4dp"
      scroll-target="#scroll-anchor"
      height="100em"
    >
      <v-spacer></v-spacer>

      <router-link to="/">
        <v-img
          contain
          class="mr-3"
          src="@/assets/nav-logo.png"
          width="15em"
          align-center
        >
        </v-img>
      </router-link>

      <template v-slot:extension>
        <v-tabs align-with-title color="#9AFFD2">
          <v-tab to="/"><v-icon>mdi-home</v-icon></v-tab>
          <v-tab class="hidden-md-and-down" to="/#carousel">Rückblick</v-tab>
          <v-tab class="hidden-md-and-down" to="/#review">Impressionen</v-tab>
          <v-tab to="/#gallery">Gallerie</v-tab>
        </v-tabs>
      </template>

      <v-spacer></v-spacer>
      
      <v-btn disabled icon>
        <v-icon>mdi-heart</v-icon>
      </v-btn>
    </v-app-bar>
    <v-sheet id="scroll-anchor" class="overflow-y-auto" max-height="600">
      <v-container style="height: 4em"> </v-container>
    </v-sheet>
  </v-card>
</template>
<script>
export default {
  name: 'Navbar',
  methods: {
    flip () {
      this.$emit('setdrawer', true)
    },
    opensearch () {
      this.$emit('opensearch')
    }
  }
}
</script>