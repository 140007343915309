<template>
    <v-row >
      <v-col offset-xl="4" offset-lg="4" offset-md="4" xl="4" lg="4" md="4" sm="12" xs="12">
        <v-card elevation="3" class="mx-3 my-4">
          <v-img :src="item.image"></v-img>
          <v-card-title v-text="item.title"></v-card-title>
          <v-card-text v-html="item.text">
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>
<script>
export default {
  data: () => ({
    item : {
      image: require("@/assets/error/500.jpg"),
      title: "Ein Fehler ist aufgetreten :(",
      text: "Er ist tot, Jim. Lieber zurück auf die <a href='/'>Startseite</a>?"
    }
  })
}
</script>
