<template>
  <v-container id="top" class="mt-3">
    <v-alert id="success" v-if="success" prominent type="success">
      <v-row align="center">
        <v-col class="grow">
          Deine Bilder wurden erfolgreich hochgeladen. Schau doch in die
          Galerie, eventuell findest du sie dort ;-)
        </v-col>
        <v-col class="shrink">
          <v-btn @click="flipsuccess()" color="secondary">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-alert id="warning" v-if="error" prominent type="warning">
      <v-row align="center">
        <v-col class="grow">
          Bitte wähle eines oder mehrere Bilder aus und versuch's erneut.
        </v-col>
        <v-col class="shrink">
          <v-btn @click="fliperror()" color="secondary">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <v-card>
      <v-img
        class="white--text align-end"
        height="500px"
        src="@/assets/upload/upload.jpg"
      >
        <v-card-title>Bilder hochladen</v-card-title>
      </v-img>
      <v-row class="text-center">
        <v-col cols="6" offset="3">
          <h1></h1>
        </v-col>
        <v-col cols="6" offset="3">
          <v-file-input
            multiple
            truncate-length="15"
            label="Bilder zum Hochladen auswählen..."
            accept="image/*"
            @change="preview"
            v-model="images"
            show-size
            prepend-icon="mdi-image-area"
            color="grey"
          >
            <template v-slot:selection="{ text }">
              <v-chip small label color="secondary">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="6" offset="3">
          <v-btn
            :loading="loading"
            @click.stop="upload()"
            elevation="2"
            color="secondary"
            :disabled="loading"
          >
            Hochladen
          </v-btn>
        </v-col>
      </v-row>
      <v-row align-center>
        <v-col
          offset="3"
          cols="6"
          v-bind:key="'url-card-' + index"
          v-for="(url, index) in urls"
        >
          <v-card elevation="3">
            <v-img contain :src="url" max-height="100"></v-img>
            <v-btn
              @click.stop="removeEntry(index)"
              color="light-grey"
              fab
              x-small
              :disabled="loading"
            >
              <v-icon color="dark-grey">mdi-trash-can-outline</v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Upload',
  data () {
    return {
      urls: [],
      images: [],
      loading: false,
      error: false,
      success: false
    }
  },
  methods: {
    preview () {
      this.error = false
      var tempUrls = []
      this.images.forEach(image => {
        tempUrls.push(URL.createObjectURL(image))
      });
      this.urls = tempUrls;
    },
    upload () {
      this.error = false
      if (this.images === null || this.images.length === 0) {
        this.error = true
        this.loading = false
        this.$vuetify.goTo("#top")
        return;
      }
      this.loading = true
      this.success = true
      this.$vuetify.goTo("#top")
    },
    fliperror () {
      this.error = !this.error
    },
    removeEntry (index) {
      this.images.splice(index, 1)
    }
  },
  watch: {
    images () {
      this.preview();
    }
  },
}
</script>
