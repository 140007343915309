
import Login from './components/Login.vue'
import Main from './components/Main.vue';
import Gallery from './components/Gallery.vue'
import Upload from './components/Upload.vue'
import SearchResults from './components/SearchResults.vue'
import NotFound from './components/error/NotFound.vue';
import Error from './components/error/Error.vue';

export default [
    { path: "/login", name: "login", component: Login },
    { path: "/", name: "main", component: Main },
    { path: "/upload", name: "upload", component: Upload },
    { path: "/gallery", name: "gallery", component: Gallery },
    { path: "/search", name: "search", component: SearchResults },
    { path: "/error", name: "error", component: Error },
    { path: "*", name: "notfound", component: NotFound }
]