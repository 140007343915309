<template>
  <v-card>
    <v-img
      height="95vh"
      src="@/assets/main/jani_janes_gehen.jpg"
      class="white--text align-end"
      gradient="to bottom, rgba(0,0,0,0.01), rgba(0,0,0,.3)"
      aspect-ratio="3"
    >
      <v-card-title class="justify-end text-right mr-8">
        <div>
          <h2>
            <div>2. April 2022</div>
          </h2>
          <h3 class="headline white--text text--accent-2">
            <b>Es war wunderbar ...</b>
          </h3>
          <span>... dank euch!</span>
        </div>
      </v-card-title>
    </v-img>
  </v-card>
</template>
<script>
export default {
  name: "Top",
  data: () => ({
    until: 100,
    endDate: new Date(2022, 4, 2, 15, 0, 0)
  }),
  computed: {
    time: function () {
      var date = new Date(0);
      date.setSeconds(this.until);

      var dateString = date.getMonth() + ' Monate ' + date.getDate() + ' Tage';
      var timeString = ' ' + date.toISOString().substr(11, 8) + ' Stunden';

      return dateString + timeString;
    }
  },
  mounted: function () {
    setInterval(() => {
      this.until = (this.endDate.getTime() - new Date().getTime()) / 1000;
    }, 1000);
  }
}
</script>